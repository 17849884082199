export enum Attributes {
    mainImage = "main",
    outsideImage = "outside",
    color = "Color",
    colorCode = "ColorCode",
    dominantColor = "DominantColor",
    backgroundColor = "BackgroundColor",
    productColors = "ProductColors",
    size = "Size",
    Gender = "Gender",
    articleNumber = "ArticleNumber",
    productNumber = "ProductNumber",
    descriptiveHeading = "DescriptiveHeading",
    fbStory = "FBStory",
    fbText1 = "FBText1",
    fbText2 = "FBText2",
    fbText3 = "FBText3",
    fbText4 = "FBText4",
    fbText5 = "FBText5",
    fbText6 = "FBText6",
    Division = "Division",
    collectionCategory = "CollectionCategory",
    collectionType = "CollectionType",
    collectionClass = "CollectionClass",
    CollectionSegment = "CollectionSegment",
    collectionCategoryCode = "CollectionCategoryCode",
    pageTitle = "PageTitle",
    countryOfOrigin = "CountryOfOrigin",
    howToUse = "HowToUse",
    MaterialGroup = "MaterialGroupLocalized",
    netQuantity = "NetQuantity",
    TargetGroup = "TargetGroup",
    sizeUS = "Size_US",
    sizeUK = "Size_UK",
    sizeCN = "Size_CN",
    sizeKR = "Size_KR",
    sizeJP = "Size_JP",
    sizeCA = "Size_CA",
    productNameCustomerFriendly = "ProductNameCustomerFriendly",
    ProductVideo = "ProductVideo",
    InteractiveVideo = "InteractiveVideo",
    CustomBadge1 = "CustomBadge1",
    CustomBadge2 = "CustomBadge2",
    CustomBadge3 = "CustomBadge3",
}

export enum CustomAttributes {
    discountPercentage = "price_CF_DiscountPercentage",
    recommendedPrice = "price_CF_RecommendedRetailPrice",
    offerIds = "price_CF_OfferIds",
    previousLowestPrice = "price_CF_PreviousLowestPrice",
    adjustedPrice = "price_CF_AdjustedPrice",
    lineItemPrice = "line-item_CF_Price",
    lineItemDiscountedPrice = "line-item_CF_DiscountedPrice",
}
