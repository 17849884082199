import {
    QueryClient,
    useQuery,
    useMutation,
    type UseMutationOptions,
    type UseQueryOptions,
    type QueryKey,
    type FetchQueryOptions,
    QueryCache,
} from "@tanstack/react-query";
import { Logger, type LogTag, type ServiceType } from "@lib/monitoring/logger";

import type { AxiosRequestConfig } from "axios";
import { FetchServiceAxios } from "./fetchServiceAxios";

// Types
export type ApiError = {
    error: string;
    name?: string;
    stack?: string;
    status: number;
    message?: string;
};

export type QueryOnlyConfig<TData> = Omit<
    UseQueryOptions<TData, ApiError, TData>,
    "queryKey" | "queryFn"
>;

export type QueryConfig<TData> = QueryOnlyConfig<TData> & {
    axiosConfig?: AxiosRequestConfig;
};

export type MutationConfig<TData, TVariables> = Omit<
    UseMutationOptions<TData, ApiError, TVariables>,
    "mutationFn"
> & {
    axiosConfig?: AxiosRequestConfig;
    method?: "POST" | "PUT" | "DELETE";
};
export const useApi = {
    // Async function wrapper for React Query.
    /**
     *
     * @param queryKey Key of the request, useful to invalidate when need fresh data
     * @param queryFunction async function to be executed
     * @param config query
     */
    useAsync: <TData>(
        queryKey: QueryKey,
        queryFunction: () => Promise<TData>,
        config: QueryOnlyConfig<TData> = {}
    ) => {
        return useQuery<TData, ApiError>({
            queryKey,
            queryFn: async () => await queryFunction(),
            ...config,
        });
    },

    // Mutation Wrapper
    // POST, PUT or DELETE wrapper. We are using "useMutate" to avoid confusion with...
    // ... useMutation from react-query Library
    /**
     *
     * @param url to the make the request, default is POST
     * @param options axios config or mutationConfig, if needed specify method, onSuccess handler, onError handlers, etc
     */
    useMutate: <TRequest, TResponse>(url: string, config?: MutationConfig<TResponse, TRequest>) => {
        const { axiosConfig, method = "POST", ...mutationConfig } = config || {};

        return useMutation<TResponse, ApiError, TRequest>({
            mutationFn: async (variables) => {
                try {
                    switch (method) {
                        case "PUT":
                            return await FetchServiceAxios.put<TResponse>(
                                url,
                                variables,
                                axiosConfig
                            );
                        case "DELETE":
                            return await FetchServiceAxios.delete<TResponse>(
                                url,
                                variables,
                                axiosConfig
                            );
                        case "POST":
                        default:
                            return await FetchServiceAxios.post<TRequest, TResponse>(
                                url,
                                variables,
                                axiosConfig
                            );
                    }
                } catch (error) {
                    return Promise.reject(error);
                }
            },
            ...mutationConfig,
        });
    },

    // Prefetch Wrapper
    /**
     *
     * @param queryClient We need a new instance of queryClient to avoid conflicts with the current instance
     * @param queryKey Key of the request, useful to invalidate when need fresh data or hydrate on the client
     * @param queryFunction async function to be executed
     * @param options axios config or queryConfig
     */
    usePrefetch: async <TData>(
        queryClient: QueryClient,
        queryKey: QueryKey,
        queryFunction: () => Promise<TData>,
        options?: FetchQueryOptions<TData>
    ) => {
        await queryClient.prefetchQuery({
            queryKey,
            queryFn: async () => await queryFunction(),
            ...options,
        });

        return queryClient;
    },
};

export const getQueryClient = () => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000 * 5,
                retry: 3,
                // If retryOnMount is enabled, re-renders from errors can cause a feedback loop causing endless fetching because the component re-mounts.
                // retryOnMount: false,
            },
        },
        queryCache: new QueryCache({
            onError: (error, query) => {
                if (!query.meta) {
                    //eslint-disable-next-line no-console
                    return console.error("Query error without Logger: ", error, query);
                }
                // TODO: Add types to the logger - re visit how we are passing the logger
                Logger.error(query.meta.serviceType as ServiceType, query.meta.message as string, {
                    tag: query.meta.tag as LogTag,
                    ...(query.meta.payload as Record<string, any>),
                    error: {
                        ...error,
                    },
                });
            },
        }),
    });
};
