import { SizeLocales } from "@lib/constants/filtersConstants";
import { OrderValidationFormat } from "@lib/validators/regex";
import type { SiteConfigEntry } from "./types";
import { Market } from "./types";

export const siteConfig: Record<Market, SiteConfigEntry> = {
    [Market.MY]: {
        country: "MY",
        countryName: "Malaysia",
        language: ["en"],
        currency: "MYR",
        fractionDigits: 2,
        shoeSizingConventions: [SizeLocales.EU, SizeLocales.US],
        sizeGuideCollections: [
            SizeLocales.US,
            SizeLocales.CA,
            SizeLocales.EU,
            SizeLocales.UK,
            SizeLocales.CM,
        ],
        checkout: {
            countryCode: "+60",
        },
        // Get geo data from https://github.com/mihai-craita/countries_center_box/blob/master/countries.json
        geo: {
            centerLat: 4.210484,
            centerLng: 101.975766,
            swLat: 0.853821,
            swLng: 99.640573,
            neLat: 7.363468,
            neLng: 119.269362,
            // initial zoom level defined by UX team
            initZoomLevel: 6,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.MY],
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
    },
    [Market.SG]: {
        country: "SG",
        countryName: "Singapore",
        language: ["en"],
        currency: "SGD",
        fractionDigits: 2,
        shoeSizingConventions: [SizeLocales.EU, SizeLocales.US],
        sizeGuideCollections: [SizeLocales.US, SizeLocales.EU, SizeLocales.UK, SizeLocales.CM],
        checkout: {
            countryCode: "+65",
        },
        geo: {
            centerLat: 1.352083,
            centerLng: 103.819836,
            swLat: 1.166398,
            swLng: 103.605575,
            neLat: 1.470772,
            neLng: 104.085557,
            initZoomLevel: 11,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.SG],
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
    },
    [Market.ES]: {
        country: "ES",
        countryName: "Spain",
        language: ["es"],
        currency: "EUR",
        fractionDigits: 2,
        shoeSizingConventions: [SizeLocales.EU, SizeLocales.UK],
        sizeGuideCollections: [SizeLocales.EU, SizeLocales.UK, SizeLocales.CM],
        checkout: {
            countryCode: "+34",
        },
        geo: {
            centerLat: 40.463667,
            centerLng: -3.74922,
            swLat: 27.637789,
            swLng: -18.160788,
            neLat: 43.79238,
            neLng: 4.327784,
            initZoomLevel: 6,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.ES],
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
        insiderTagLoaded: true,
    },
    [Market.EE]: {
        country: "EE",
        countryName: "Estonian",
        language: ["et"],
        currency: "EUR",
        fractionDigits: 2,
        shoeSizingConventions: [SizeLocales.EU, SizeLocales.UK],
        sizeGuideCollections: [SizeLocales.EU, SizeLocales.UK, SizeLocales.CM],
        checkout: {
            countryCode: "+372",
        },
        geo: {
            centerLat: 58.595272,
            centerLng: 25.013607,
            swLat: 57.509316,
            swLng: 21.764313,
            neLat: 59.685685,
            neLng: 28.210139,
            initZoomLevel: 8,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.EE],
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
        insiderTagLoaded: true,
    },
    [Market.IN]: {
        country: "IN",
        countryName: "India",
        language: ["en"],
        currency: "INR",
        fractionDigits: 2,
        shoeSizingConventions: [SizeLocales.UK, SizeLocales.EU],
        sizeGuideCollections: [SizeLocales.US, SizeLocales.EU, SizeLocales.UK, SizeLocales.CM],
        checkout: {
            countryCode: "+91",
        },
        geo: {
            centerLat: 20.593684,
            centerLng: 78.96288,
            swLat: 6.753516,
            swLng: 68.162386,
            neLat: 35.504475,
            neLng: 97.395555,
            initZoomLevel: 6,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.IN],
        isProductInfoRequired: true,
        showPriceLegalText: true,
        reCaptchaIntegration: {
            contactForm: true,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
    },
    [Market.KR]: {
        country: "KR",
        countryName: "South Korea",
        language: ["ko"],
        currency: "KRW",
        shoeSizingConventions: [SizeLocales.KR, SizeLocales.EU],
        sizeGuideCollections: [SizeLocales.KR, SizeLocales.EU, SizeLocales.US, SizeLocales.CM],
        fractionDigits: 0,
        checkout: {
            countryCode: "+82",
        },
        geo: {
            centerLat: 35.907757,
            centerLng: 127.766922,
            swLat: 33.10611,
            swLng: 124.608139,
            neLat: 38.616931,
            neLng: 130.923218,
            initZoomLevel: 8,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.KR],
        isProductInfoRequired: true,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: true,
    },
    [Market.CA]: {
        country: "CA",
        countryName: "Canada",
        language: ["en", "fr"],
        currency: "CAD",
        shoeSizingConventions: [SizeLocales.CA, SizeLocales.EU],
        sizeGuideCollections: [SizeLocales.CA, SizeLocales.US, SizeLocales.EU, SizeLocales.CM],
        fractionDigits: 2,
        checkout: {
            countryCode: "+1",
            requiresSalesTaxEstimation: true,
            hasShippingFees: true,
        },
        geo: {
            centerLat: 56.130366,
            centerLng: -106.346771,
            swLat: 41.676556,
            swLng: -141.00187,
            neLat: 83.115061,
            neLng: -52.619409,
            initZoomLevel: 4,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.CA],
        reCaptchaIntegration: {
            contactForm: true,
            checkout: true,
        },
        liveChat: true,
        isCountryChangeForBillingAddressAllowed: false,
        isOrderHistoryRoutesAllowed: true,
    },
    [Market.US]: {
        country: "US",
        countryName: "United States",
        language: ["en"],
        currency: "USD",
        shoeSizingConventions: [SizeLocales.US, SizeLocales.EU],
        sizeGuideCollections: [SizeLocales.US, SizeLocales.CA, SizeLocales.EU, SizeLocales.CM],
        fractionDigits: 2,
        checkout: {
            countryCode: "+1",
            requiresSalesTaxEstimation: true,
            hasShippingFees: true,
            hasShippingDetails: true,
        },
        geo: {
            centerLat: 39.50024,
            centerLng: -98.350891,
            swLat: 25.820898,
            swLng: -124.712891,
            neLat: 49.38024,
            neLng: -66.940662,
            initZoomLevel: 5,
            imperialMetricSystem: true,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.US],
        reCaptchaIntegration: {
            contactForm: true,
            checkout: true,
        },
        liveChat: true,
        isCountryChangeForBillingAddressAllowed: false,
        smartBanner: {
            iOSAppId: "6477891321",
            googleAppId: "com.ecco.us.android",
        },
        isOrderHistoryRoutesAllowed: true,
        supportsGlobalPrivacyControl: true,
    },
    [Market.AU]: {
        country: "AU",
        countryName: "Australia",
        language: ["en"],
        currency: "AUD",
        shoeSizingConventions: [SizeLocales.EU, SizeLocales.US],
        sizeGuideCollections: [SizeLocales.AU, SizeLocales.EU, SizeLocales.CN, SizeLocales.CM],
        fractionDigits: 0,
        checkout: {
            countryCode: "+61",
            requiresSalesTaxEstimation: true,
        },
        geo: {
            centerLat: -25.274398,
            centerLng: 133.775136,
            swLat: -54.777219,
            swLng: 112.921454,
            neLat: -9.221084,
            neLng: 159.255528,
            initZoomLevel: 5,
            imperialMetricSystem: true,
        },
        liveChat: true,
        orderNumberFormatRegex: OrderValidationFormat[Market.AU],
        reCaptchaIntegration: {
            contactForm: true,
            checkout: true,
        },
        isCountryChangeForBillingAddressAllowed: false,
        isOrderHistoryRoutesAllowed: true,
    },
    [Market.JP]: {
        country: "JP",
        countryName: "Japan",
        language: ["ja"],
        currency: "JPY",
        shoeSizingConventions: [SizeLocales.JP, SizeLocales.EU],
        sizeGuideCollections: [SizeLocales.JP, SizeLocales.EU, SizeLocales.US, SizeLocales.CM],
        fractionDigits: 3,
        checkout: {
            countryCode: "+81",
            requiresSalesTaxEstimation: true,
            minAddressLookupLength: 7,
        },
        geo: {
            centerLat: 36.204824,
            centerLng: 138.252924,
            swLat: 24.046045,
            swLng: 122.93383,
            neLat: 45.522772,
            neLng: 153.98743,
            initZoomLevel: 5,
        },
        orderNumberFormatRegex: OrderValidationFormat[Market.JP],
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
        isCountryChangeForBillingAddressAllowed: false,
        isOrderHistoryRoutesAllowed: true,
        isGiftWrappingAllowed: true,
        amazonPay: {
            enabled: process.env.NEXT_PUBLIC_AMAZON_PAY_ENABLED === "true",
            checkoutBannerEnabled: false,
            checkoutJsUrl: "https://static-fe.payments-amazon.com/checkout.js",
            merchantId: "A2YSIM42GJ1TXQ",
            region: "jp",
        },
    },
};
