import { SizeLocales } from "@lib/constants/filtersConstants";
import { siteConfig } from "./localizationConfigObject";
import type { SiteConfigEntry } from "./types";

const getHostnameSiteConfig = (): SiteConfigEntry => {
    const HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME;

    if (!HOSTNAME) {
        throw new Error(`Missing env variable HOSTNAME and NEXT_PUBLIC_HOSTNAME`);
    }
    const countryCode = HOSTNAME.split(".")[0];

    const hostNameConfig = siteConfig[countryCode];
    if (!hostNameConfig) {
        throw new Error(`No Config found for hostname ${HOSTNAME}`);
    }
    return hostNameConfig;
};

export const activeSiteConfig = getHostnameSiteConfig();

export const {
    currency: activeSiteCurrency,
    country: activeSiteCountry,
    fractionDigits: activeSiteFractionDigits,
    liveChat: activeSiteHasLiveChat,
    smartBanner: activeSiteSmartBanner,
    sizeGuideCollections: activeSiteSizeGuideCollections,
} = activeSiteConfig;

export const getCountryCode = activeSiteConfig?.checkout.countryCode;

export const getOrderNumberFormatRegex = activeSiteConfig?.orderNumberFormatRegex;

export const getLanguageFromLocale = (locale: string) => {
    return locale.split("-")[0];
};

export const getCheckoutForSite = activeSiteConfig?.checkout;

export const shoeConventionForSite = activeSiteConfig.shoeSizingConventions || [
    SizeLocales.EU,
    SizeLocales.US,
];

export const reCaptchaIntegrationEnabled =
    activeSiteConfig.reCaptchaIntegration.checkout ||
    activeSiteConfig.reCaptchaIntegration.contactForm;

export const getCountryForSiteByLocale = (locale: string) => {
    return locale.split("-")[1];
};

export const getCurrencyForSiteByLocale = (locale: string) => {
    const country = locale.split("-")[1].toLowerCase();
    return siteConfig[country].currency;
};
