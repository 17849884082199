import type { SizeLocales } from "@lib/constants/filtersConstants";
import type { TCountryCode, TLanguageCode } from "countries-list";

export type CountryCode = TCountryCode;
export type LanguageCode = TLanguageCode;

export enum Market {
    MY = "my",
    SG = "sg",
    ES = "es",
    EE = "ee",
    IN = "in",
    KR = "kr",
    CA = "ca",
    US = "us",
    AU = "au",
    JP = "jp",
}

export interface SiteConfigEntry {
    country: CountryCode;
    countryName: string;
    language: LanguageCode[];
    currency: string;
    fractionDigits: number;
    shoeSizingConventions: SizeLocales[];
    sizeGuideCollections: SizeLocales[];
    checkout: {
        countryCode: string;
        requiresSalesTaxEstimation?: boolean;
        hasShippingFees?: boolean;
        hasShippingDetails?: boolean;
        minAddressLookupLength?: number;
    };
    geo: {
        centerLat: number;
        centerLng: number;
        swLat: number;
        swLng: number;
        neLat: number;
        neLng: number;
        initZoomLevel?: number;
        imperialMetricSystem?: boolean;
    };
    orderNumberFormatRegex: RegExp;
    reCaptchaIntegration: {
        contactForm: boolean;
        checkout: boolean;
    };
    liveChat?: boolean;
    smartBanner?: {
        iOSAppId: string;
        googleAppId: string;
    };
    isCountryChangeForBillingAddressAllowed?: boolean;
    isOrderHistoryRoutesAllowed?: boolean;
    isGiftWrappingAllowed?: boolean;
    isProductInfoRequired?: boolean;
    showPriceLegalText?: boolean;
    amazonPay?: {
        enabled: boolean;
        checkoutBannerEnabled: boolean;
        merchantId: string;
        region: string;
        // This is dependant on the region
        checkoutJsUrl: string;
    };
    supportsGlobalPrivacyControl?: boolean;
    insiderTagLoaded?: boolean;
}
