import type { Customer, ProductVariant } from "@graphql/generated/components";
import { produce } from "immer";
import type { StoreApi } from "zustand";
import { createStore, useStore as useZustandStore } from "zustand";
import useIsomorphicLayoutEffect from "@ui/hooks/useIsomorphicLayoutEffect";
import { useContext, createContext, useRef } from "react";
import { shoeConventionForSite } from "@config/site/site-config";
import { persist } from "zustand/middleware";
import { merge } from "lodash-es";
import { initialState } from "./initialState";
import type { CustomerCustomFields, StoreDataType, StoreState, Tracking } from "./types";
import type { MeCustomerRequest } from "@interfaces/storefront-api";
import type { StoreData } from "@components/store-locator/controller";

export const initializeStore = (preloadedState = {}) => {
    return createStore<StoreState>()(
        persist(
            (set, get) => ({
                data: {
                    ...initialState,
                    ...preloadedState,
                },
                setLocked: (payload) =>
                    set(
                        produce((state) => {
                            state.isLocked = payload;
                        })
                    ),
                setRefreshToken: (payload) =>
                    set(
                        produce((state) => {
                            state.data.refreshToken = payload;
                        })
                    ),
                setActiveData: (cartAndWishlist) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = cartAndWishlist.cart;
                            state.data.me.wishlistInfo.wishlist = cartAndWishlist.wishlist;
                        })
                    ),
                setIsLoggedIn: (payload) =>
                    set(
                        produce((state) => {
                            state.data.me.isLoggedIn = payload;
                        })
                    ),
                clearMe: () =>
                    set(
                        produce((state) => {
                            state.data.me = initialState.me;
                        })
                    ),
                setPayment: (payload) =>
                    set(
                        produce((state) => {
                            state.data.payment = payload;
                        })
                    ),
                setCart: (cart) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = cart;
                        })
                    ),
                clearCart: () =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = null;
                        })
                    ),
                setWishlist: (wishlist) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.wishlist = wishlist;
                        })
                    ),
                getWishlist: () => get().data.me.wishlistInfo.wishlist,
                setGlobalShoeSizeConvention: (globalShoeSizeConvention) =>
                    set(
                        produce((state) => {
                            state.data.me.globalShoeSizeConvention = globalShoeSizeConvention;
                        })
                    ),
                clearWishlist: () =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.wishlist = null;
                            state.data.me.wishlistInfo.masterProducts = null;
                        })
                    ),
                setWishlistIsExecuted: (isExecuted) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.isExecuted = isExecuted;
                        })
                    ),
                setAddToCartIsExecuted: (isExecuted) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.isExecuted = isExecuted;
                        })
                    ),
                setItemsLimitReached: (payload) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.itemsLimitReached = payload;
                        })
                    ),
                setMasterProducts: (masterProducts) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.masterProducts = masterProducts;
                        })
                    ),
                setWishlistMasterProducts: (masterProducts) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.masterProducts = masterProducts;
                        })
                    ),
                setShippingMethods: (shippingMethods) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.shippingMethods = shippingMethods;
                        })
                    ),
                setProducts: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.list = payload;
                        })
                    ),
                setResultsCount: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.resultsCount = payload;
                        })
                    ),
                setCurrentFilters: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.currentFilters = payload;
                        })
                    ),
                setLastLoadedPage: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.lastLoadedPage = payload;
                        })
                    ),
                setLastLoadedPlpContainerHeight: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.lastVisitedPlpContainerHeight = payload;
                        })
                    ),
                setInitialFilters: (payload: { color: any; size: any; category: any }) =>
                    set(
                        produce((state) => {
                            state.data.products.initialFilters = payload;
                        })
                    ),
                setLastVisitedPlp: (payload: string) =>
                    set(
                        produce((state) => {
                            state.data.products.lastVisitedPlp = payload;
                        })
                    ),
                setDraftPromoCode: (draftPromoCode: string) =>
                    set(
                        produce((state) => {
                            state.data.draftPromoCode = draftPromoCode;
                        })
                    ),
                setHasFreshData: (hasFreshData: boolean) =>
                    set(
                        produce((state) => {
                            state.data.hasFreshData = hasFreshData;
                        })
                    ),
                setCustomer: (customer: Customer) =>
                    set(
                        produce((state) => {
                            state.data.me.customer = customer;
                        })
                    ),
                setCustomerVersion: (customer: Customer) =>
                    set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const { id, version } = state.data.me.customer;
                            if (id !== customer.id) {
                                state.data.me.customer = customer;
                                return;
                            }
                            if (version > customer.version) {
                                return;
                            }
                            state.data.me.customer.version = customer.version;
                        })
                    ),
                setMyAddresses: (customer: Customer) =>
                    set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const { id, version, addresses, defaultShippingAddressId } =
                                state.data.me.customer;
                            if (id !== customer.id) {
                                state.data.me.customer.id = customer.id;
                                state.data.me.customer.version = customer.version;
                                state.data.me.customer.addresses = customer.addresses;
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                                return;
                            }
                            if (!version) {
                                state.data.me.customer.version = customer.version;
                            }
                            if (version < customer.version) {
                                // if equal no need to set, if greater better not set
                                state.data.me.customer.version = customer.version;
                            }

                            if (JSON.stringify(addresses) !== JSON.stringify(customer.addresses)) {
                                state.data.me.customer.addresses = customer.addresses;
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                                return;
                            }
                            if (!defaultShippingAddressId) {
                                return;
                            }
                            if (defaultShippingAddressId !== customer?.defaultShippingAddressId) {
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                            }
                        })
                    ),
                getStoreData: () => get().data.storeData,
                setStoreData: (newData: StoreData) =>
                    set(
                        produce((state) => {
                            state.data.storeData = newData;
                        })
                    ),
                getPolicyAccepted: () => get().data.policyAccepted,
                setPolicyAccepted: (policyAccepted: boolean) =>
                    set(
                        produce((state) => {
                            state.data.policyAccepted = policyAccepted;
                        })
                    ),
                setMyProfile: (customer: Customer & MeCustomerRequest) => {
                    return set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const customFields = {} as CustomerCustomFields;
                            customer.custom?.customFieldsRaw?.forEach((field) => {
                                customFields[field.name] = field.value;
                            });
                            state.data.me.customer.phoneticFirstName =
                                customer.phoneticFirstName || "";
                            state.data.me.customer.phoneticLastName =
                                customer.phoneticLastName || "";
                            state.data.me.customer.countryCode = customer.phoneCountryCode || "";
                            state.data.me.customer.phoneNumber = customer.phoneNumber || "";
                            state.data.me.customer.gender = customer.gender || "";
                            state.data.me.customer.email = customer.email;
                            state.data.me.customer.externalId = customer.externalId;
                            if (customer.id && customer.id !== state.data.me.customer.id) {
                                state.data.me.customer.id = customer.id;
                                state.data.me.customer.version = customer.version;
                            } else {
                                //it's the same customer let's figure out what the version state potentially is
                                if (!state.data.me.customer.version) {
                                    state.data.me.customer.version = customer.version;
                                }
                                if (state.data.me.customer.version < customer.version) {
                                    // if equal no need to set, if greater better not set
                                    state.data.me.customer.version = customer.version;
                                }
                            }

                            state.data.me.customer.dateOfBirth = customer.dateOfBirth;
                            state.data.me.customer.firstName = customer.firstName;
                            state.data.me.customer.lastName = customer.lastName;
                        })
                    );
                },
                setUtmParams: (utmParams: Tracking["utm"]) =>
                    set(
                        produce((state) => {
                            state.data.tracking.utm = utmParams;
                        })
                    ),
                getUtmParams: () => get().data.tracking.utm,
                getCart: () => get().data.me.cartInfo.cart,
                getMasterProducts: () => get().data.me.cartInfo.masterProducts,
                setSelectedVariant: (selectedVariant: ProductVariant | null) =>
                    set(
                        produce((state) => {
                            state.data.pdpState.selectedVariant = selectedVariant;
                        })
                    ),
            }),
            {
                partialize: (state) => ({
                    data: {
                        me: {
                            globalShoeSizeConvention: state.data.me.globalShoeSizeConvention,
                        },
                        storeData: state.data.storeData,
                    },
                }),
                name: "ecco-store",
                merge: (persistedState: Partial<StoreState>, currentState) => {
                    if (
                        !shoeConventionForSite.includes(
                            persistedState.data.me.globalShoeSizeConvention
                        )
                    ) {
                        return merge(currentState, {
                            data: {
                                me: {
                                    globalShoeSizeConvention: shoeConventionForSite[0],
                                },
                            },
                        });
                    } else {
                        return merge(currentState, persistedState);
                    }
                },
            }
        )
    );
};

const zustandContext = createContext<StoreApi<StoreState>>(initializeStore(initialState));

export const ZustandProvider = ({ children, initialState = {} }) => {
    const storeRef = useRef<StoreApi<StoreState>>();
    if (!storeRef.current) {
        storeRef.current = initializeStore(initialState);
    }

    return <zustandContext.Provider value={storeRef.current}>{children}</zustandContext.Provider>;
};

export function useStore<T>(selector: (state: StoreState) => T) {
    const store = useContext(zustandContext);

    if (!store) throw new Error("Store is missing the provider");

    return useZustandStore(store, selector) as T;
}

export const useStoreApi = () => {
    return useContext(zustandContext);
};

export function useCreateStore(initialState: { data: StoreDataType }) {
    // For CSR, always re-use same store.
    const store = initializeStore(initialState);
    // And if initialState changes, then merge states in the next render cycle.
    //
    // eslint complaining "React Hooks must be called in the exact same order in every component render"
    // is ignorable as this code runs in same order in a given environment
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIsomorphicLayoutEffect(() => {
        if (initialState && store) {
            store.setState({
                ...store.getState(),
                ...initialState,
            });
        }
    }, [initialState]);

    return () => store;
}
