import { Market } from "../../config/site/types";

// @ts-ignore
export const onlyLetters = /\p{L}+/u;
export const onlyNumbers = /^[0-9]*$/;
export const countryCode = /^\+\d{1,3}$/;
export const JPdateRegex = /^(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12][0-9]|30|31)$/;
export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
export const emailRegex =
    /^[a-zA-Z0-9][a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
export const passwordRules = /^(?=.{12,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;
export const postalCodeCA = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
export const USMilitaryBase = /\b(?:APO|DPO|FPO)(?:\sAE)?(?!\s+$)\b/;
export const postalCodeUS = /^\d{5}(-\d{4})?$/;
export const noConsequentHyphens = /^(?!.*--).*$/;
//For PO box regex everytime when we switch it we need to notify the BE team to keep it in sync, and other way around.
export const poBox =
    /\b(?:p(?:ost)?[\.\s-]*[0o](?:ffice)?[\.\s-]*b(?:ox)?[\.\s-]*[0o]?x[\.\s-]*(?:\d*))\b/i;
export const JPFurigana = /^[\u3040-\u309F\u30A0-\u30FF]+$/;
export const postalCodeJP = /^\d{7}$/;
export const fullNameParser = /(\S+)/g;
export const numericString = /^\d+$/;

//Before Headless each market had its own formats for orders. This validators cover these requirements
export const OrderValidationFormat: Record<Market, RegExp> = {
    [Market.MY]: /^(#?MY-\d{11}|MY\d{9}|8700-\d{6}|8700R-d{6})$/,
    [Market.SG]: /^(#?(DE|EC)\d{8}|SG\d{9}|(3800|3800R)-(\d{6}|\d{9}))$/,
    [Market.ES]: /^(#?ES\d{8}|3600-\d{6}|RO-36\d{9})$/,
    [Market.EE]: /^(#?EE-\d{11}|EE\d{8}|SO-760\d{7}|RO-76\d{9})$/,
    [Market.IN]: /^(#?DE\d{8}|EC\d{8}|IN\d{9}|3820-\d{6}|SO-38\d{9})$/,
    [Market.KR]: /^(#?DE\d{8}|EC\d{8}|KR\d{9}|6800-\d{6}|SO-68\d{9}|6800R-\d{6})$/,
    [Market.JP]: /^(#?(DE|EC)\d{8}|JP\d{9}|SO-(\d{9,10})|6100-\d{5}|6100R-\d{7})$/,
    [Market.AU]: /^(#?(DE|EC)\d{8}|AU\d{9}|ST200-\d{6}|ST-\d{9}|200RO-\d{6,7})$/,
    [Market.CA]: /^(#?(DE|EC)\d{8}|CA\d{9}|(S8|R7|R8)\d{8})$/,
    [Market.US]: /^(#?(DE|EC)\d{8}|US\d{9}|(S7|R7)\d{8})$/,
};

//^0(?:\d-\d{4}-\d{4}       # 10 digits -> 0x-xxxx-xxxx
//  |\d{2}-\d{3}-\d{4}      # 10 digits -> 0xx-xxx-xxxx
//  |\d{2}-\d{4}-\d{3}      # 10 digits -> 0xx-xxxx-xxx
//  |\d{3}-\d{2}-\d{4}      # 10 digits -> 0xxx-xx-xxxx
//  |\d{4}-\d-\d{4}         # 10 digits -> 0xxxx-x-xxxx
//  |\d{9}                  # 10 digits -> 0xxxxxxxxx
//  |\d{2}-\d{4}-\d{4}      # 11 digits -> 0xx-xxxx-xxxx
//  |\d{10}                 # 11 digits -> 0xxxxxxxxxx
//)$
export const JPPhoneNumberWithDashes =
    /^0(?:\d-\d{4}-\d{4}|\d{2}-\d{3}-\d{4}|\d{2}-\d{4}-\d{3}|\d{3}-\d{2}-\d{4}|\d{4}-\d-\d{4}|\d{9}|\d{2}-\d{4}-\d{4}|\d{10})$/;
