import Script from "next/script";

export const InsiderTag = () => {
    if (process.env.NEXT_PUBLIC_INSIDER_TAG_ENABLED !== "true") return null;

    return (
        <Script
            id="insiderTag"
            src={`https://${process.env.NEXT_PUBLIC_INSIDER_TAG_MARKET}.api.useinsider.com/ins.js?id=${process.env.NEXT_PUBLIC_INSIDER_TAG_ID}`}
            strategy="beforeInteractive"
        />
    );
};
